















































import { Component, Prop } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import ButtonComponent from '@/components/ButtonComponent.vue';
import IllustrationType from '@/utils/enums/IllustrationType';
import QrcodeVue from 'qrcode.vue';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import WidgetBackground from '@/utils/widgets/WidgetBackground';

@Component({
  components: {
    ButtonComponent,
    QrcodeVue,
  },
})
export default class AppDownloadWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({ required: false, default: '' })
  private readonly title!: string;

  @Prop({ required: false, default: '' })
  private readonly description!: string;

  @Prop({ required: false, default: '' })
  private readonly btnTitle!: string;

  @Prop({ required: false, default: '' })
  private readonly btnRoute!: string;

  private illustrationType = IllustrationType.PHONE_WITHOUT_QR;

  private get backgroundClass(): string {
    const list: string[] = [];
    if (this.background) {
      list.push(WidgetBackground[this.background]);
    }
    if (this.classes) {
      list.push(this.classes);
    }
    return list.join(' ');
  }

  private get gaps(): string {
    const list: string[] = [];
    if (this.topGap) {
      list.push(this.WidgetTopGap[this.topGap]);
    }
    if (this.bottomGap) {
      list.push(this.WidgetBottomGap[this.bottomGap]);
    }
    return list.join(' ');
  }

  created(): void {
    this.setDataConfig();
  }

  private onClick(): void {
    if (this.btnRoute) {
      const buttonRoute = this.btnRoute.toString();
      try {
        const url = new URL(buttonRoute);
        const external = !!url.protocol;
        if (external) {
          window.open(buttonRoute, '_blank');
        }
      } catch {
        let r = buttonRoute;
        const matches = buttonRoute.match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
        if (matches) {
          matches.forEach((m) => {
            const prop = m.replaceAll('%', '').trim();
            if (this.$route.params[prop]) {
              r = r.replaceAll(m, this.$route.params[prop]);
            }
          });
        }
        this.$router.push(r);
      }
    }
  }
}
